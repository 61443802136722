<template>
  <!-- 财务报表 —— 临停车缴费报表 -->
  <div class="box-card" >
    <div style="text-align:left;" v-loading="loading" element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="类型：">
          <el-select popper-class="my-select" v-model="queryForm.time_type" placeholder="请选择类型" @change="typeChange()">
            <el-option label="日报" :value="1"></el-option>
            <el-option label="周报" :value="2"></el-option>
            <el-option label="月报" :value="3"></el-option>
            <el-option label="年报" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker v-model="queryForm.start_time" @change="onSubmit()" type="date" :editable="false" :clearable="false"
            placeholder="请选择日" :picker-options="pickerOptions" format="yyyy年MM月dd日"
            v-if="queryForm.time_type == 1"></el-date-picker>
          <el-date-picker v-model="queryForm.start_time" @change="onSubmit()" type="year" :editable="false"
            placeholder="请选择年" :clearable="false" format="yyyy年" v-else-if="queryForm.time_type == 4"></el-date-picker>
          <el-date-picker v-model="queryForm.start_time" @change="onSubmit()" type="month" :editable="false"
            placeholder="请选择月" :clearable="false" format="yyyy年MM月" v-else></el-date-picker>
        </el-form-item>
        <el-form-item label="停车场：">
          <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
            placeholder="请选择停车场" @change="onSubmit()">
            <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <!-- 主要内容 -->
      <!-- 查询表单 -->
      <!-- 统计图 -->
      <div class="pay_content">
        <leCard class="echart_left" style=" width: 68%;" title="缴费统计">
          <div id="bar_box"></div>
        </leCard>
        <leCard class="echart_left" title="支付方式">
          <div id="pie_box"></div>
        </leCard>
      </div>
      <leCard class="echart_left" title="缴费统计">
        <template slot="heard-left">
          <leCount :count="total_amount"></leCount>
        </template>
        <template slot="heard-right">
          <lbButton type="warning" icon="daochu" @click="exportFile" v-preventReClick
            v-if="isShowBtn('7dcf66bfce6d4168acc75bc5') || isShowBtn('d81a2c9e1ada47cda6dc81c1')">导出</lbButton>
        </template>
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
             <template slot-scope="{row}" slot="report_time">
              <div v-if="queryForm.time_type == 1">{{ $moment(row.report_time).format("YYYY-MM-DD") }}</div>
              <div v-if="queryForm.time_type == 2">{{ row.report_time }}</div>
              <div v-if="queryForm.time_type == 3">{{ $moment(row.report_time).format("YYYY年MM月") }}</div>
              <div v-if="queryForm.time_type == 4">{{ $moment(row.report_time).format("YYYY年") }}</div>
            </template>
            <template slot-scope="{row}" slot="calculate_cost_temporary">
              ￥ {{ row.calculate_cost_temporary.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="total_payment_income_temporary">
              ￥ {{row.total_payment_income_temporary.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="total_discount_amount">
              ￥{{ row.total_discount_amount.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="total_payment_refund_temporary">
              <span :style="row.total_payment_refund_temporary > 0 ? 'color:red;' : ''">{{row.total_payment_refund_temporary > 0?'-￥':'￥'}}
                {{ row.total_payment_refund_temporary.toFixed(2) }}</span>
            </template>
             <template slot-scope="{row}" slot="total_payment_temporary">
              ￥ {{ row.total_payment_temporary.toFixed(2) }}
            </template>
             <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="showPie(row)"></lbButton>
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column label="时间" min-width="120">
            <template slot-scope="scope">
              <div v-if="queryForm.time_type == 1">{{ $moment(scope.row.report_time).format("YYYY-MM-DD") }}</div>
              <div v-if="queryForm.time_type == 2">{{ scope.row.report_time }}</div>
              <div v-if="queryForm.time_type == 3">{{ $moment(scope.row.report_time).format("YYYY年MM月") }}</div>
              <div v-if="queryForm.time_type == 4">{{ $moment(scope.row.report_time).format("YYYY年") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="parking_name" label="停车场" min-width="120"></el-table-column>
          <el-table-column label="应收" width="100">
            <template slot-scope="scope">
              ￥ {{ scope.row.calculate_cost_temporary.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="实收" width="100">
            <template slot-scope="scope">
              ￥ {{ scope.row.total_payment_income_temporary.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="优惠" width="100">
            <template slot-scope="scope">
              ￥{{ scope.row.total_discount_amount.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="退款" width="100">
            <template slot-scope="scope">
              <span :style="scope.row.total_payment_refund_temporary > 0 ? 'color:red;' : ''">{{scope.row.total_payment_refund_temporary > 0?'-￥':'￥'}}
                {{ scope.row.total_payment_refund_temporary.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="总计" width="100">
            <template slot-scope="scope">
              ￥ {{ scope.row.total_payment_temporary.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="showPie(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </leCard>
    </div>
    <lebo-dialog append-to-body title="查看" :isShow="isPieShow" width="60%"
       style="text-align:left;" @close="isPieShow=false" footerSlot closeOnClickModal>
      <div class="dialog_content">
        <div id="show_box"></div>
        <div class="show_form">
          <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom:20px;">
            <span>停车场：{{ detailPage.parking_name }}</span>
            <div>时间：
              <span v-if="queryForm.time_type == 1">{{ $moment(detailPage.report_time).format("YYYY-MM-DD") }}</span>
              <span v-if="queryForm.time_type == 2">{{ detailPage.report_time }}</span>
              <span v-if="queryForm.time_type == 3">{{ $moment(detailPage.report_time).format("YYYY-MM") }}月</span>
              <span v-if="queryForm.time_type == 4">{{ $moment(detailPage.report_time).format("YYYY") }}年</span>
            </div>
          </div>
          <table border="1" cellpadding="12" cellspacing="0" class="tableClass">
            <tr>
              <td class="first_td">应收</td>
              <td style="width: 40%;">￥ {{ detailPage.calculate_cost_temporary ?
                detailPage.calculate_cost_temporary.toFixed(2) : '0.00' }}</td>
              <td style="width: 40%;"></td>
            </tr>
            <tr>
              <td class="first_td">实收</td>
              <td> ￥ {{ detailPage.total_payment_income_temporary ? detailPage.total_payment_income_temporary.toFixed(2) :
                '0.00' }}</td>
              <td>
                <div v-if="detailPage.wechat_payment_income_temporary > 0">微信：
                  ￥ {{ detailPage.wechat_payment_income_temporary ?
                    detailPage.wechat_payment_income_temporary.toFixed(2) : '0.00' }}
                </div>
                <div v-if="detailPage.alipay_payment_income_temporary > 0">支付宝：
                  ￥ {{ detailPage.alipay_payment_income_temporary ?
                    detailPage.alipay_payment_income_temporary.toFixed(2) : '0.00' }}
                </div>
                <div v-if="detailPage.cash_payment_income_temporary > 0">现金：
                  ￥ {{ detailPage.cash_payment_income_temporary ?
                    detailPage.cash_payment_income_temporary.toFixed(2) : '0.00' }}
                </div>
                <div v-if="detailPage.ETC_payment_income_temporary > 0">ETC：
                  ￥ {{ detailPage.ETC_payment_income_temporary ? detailPage.ETC_payment_income_temporary.toFixed(2) : '0.00' }}
                </div>

              </td>
            </tr>
            <tr>
              <td class="first_td">优惠</td>
              <td>￥ {{ detailPage.total_discount_amount?detailPage.total_discount_amount.toFixed(2): '0.00'}}</td>
              <td></td>
            </tr>
            <tr>
              <td class="first_td">退款</td>
              <td>
                <span v-if="detailPage.total_payment_refund_temporary > 0"
                  style="color:red">-￥ {{ detailPage.total_payment_refund_temporary.toFixed(2) }}</span>
                <span v-else>￥ 0.00</span>
              </td>
              <td>
                <div v-if="detailPage.wechat_payment_refund_temporary > 0">微信：
                  <span style="color:red">-￥ {{ detailPage.wechat_payment_refund_temporary ?
                    detailPage.wechat_payment_refund_temporary.toFixed(2) : '0.00' }}</span>
                </div>
                <div v-if="detailPage.alipay_payment_refund_temporary > 0">支付宝：
                  <span style="color:red">-￥ {{ detailPage.alipay_payment_refund_temporary ?
                    detailPage.alipay_payment_refund_temporary.toFixed(2) : '0.00' }}</span>
                </div>
                <div v-if="detailPage.cash_payment_refund_temporary > 0">现金：
                  <span style="color:red">-￥ {{ detailPage.cash_payment_refund_temporary ?
                    detailPage.cash_payment_refund_temporary.toFixed(2) : '0.00' }}</span>
                </div>
                <div v-if="detailPage.ETC_payment_refund_temporary > 0">ETC：
                  <span style="color:red">-￥ {{ detailPage.ETC_payment_refund_temporary ?
                    detailPage.ETC_payment_refund_temporary.toFixed(2) : '0.00' }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="first_td">总计</td>
              <td> ￥ {{ detailPage.total_payment_temporary ? detailPage.total_payment_temporary.toFixed(2) : '0.00' }}</td>
              <td></td>
            </tr>
          </table>
        </div>
        <!-- <el-form class="show_form" ref="form" :model="detailPage" label-width="120px">
          <el-form-item label="停车场：">
            <div>{{detailPage.parking_name}}</div>
          </el-form-item>
          <el-form-item label="时间：">
            <div v-if="queryForm.time_type == 1">{{ $moment(detailPage.report_time).format("YYYY-MM-DD") }}</div>
            <div v-if="queryForm.time_type == 2">{{ detailPage.report_time }}</div>
            <div v-if="queryForm.time_type == 3">{{ $moment(detailPage.report_time).format("YYYY-MM") }}月</div>
            <div v-if="queryForm.time_type == 4">{{ $moment(detailPage.report_time).format("YYYY") }}年</div>
          </el-form-item>
          <el-form-item label="微信收费：">
            <div>{{'￥'+ detailPage.wechat_payment_income_temporary}}</div>
          </el-form-item>
          <el-form-item label="支付宝收费：">
            <div>{{'￥'+ detailPage.alipay_payment_income_temporary}}</div>
          </el-form-item>
          <el-form-item label="现金收费：">
            <div>{{'￥'+ detailPage.cash_payment_income_temporary}}</div>
          </el-form-item>
          <el-form-item label="ETC收费：">
            <div>{{'￥'+ detailPage.ETC_payment_income_temporary}}</div>
          </el-form-item>
          <el-form-item label="微信退款：" v-if="detailPage.wechat_payment_refund_temporary != 0 ">
            <div>{{'￥'+ detailPage.wechat_payment_refund_temporary}}</div>
          </el-form-item>
          <el-form-item label="支付宝退款：" v-if="detailPage.alipay_payment_refund_temporary != 0 ">
            <div>{{'￥'+ detailPage.alipay_payment_refund_temporary}}</div>
          </el-form-item>
          <el-form-item label="现金退款：" v-if="detailPage.cash_payment_refund_temporary != 0 ">
            <div>{{'￥'+ detailPage.cash_payment_refund_temporary}}</div>
          </el-form-item>
          <el-form-item label="ETC退款：" v-if="detailPage.ETC_payment_refund_temporary != 0 ">
            <div>{{'￥'+ detailPage.ETC_payment_refund_temporary}}</div>
          </el-form-item>
          <el-form-item label="订单退款：" v-if="detailPage.total_payment_refund_temporary != 0 ">
            <div>{{'￥'+ detailPage.total_payment_refund_temporary}}</div>
          </el-form-item>
          <el-form-item label="收费总计：">
            <div>{{'￥'+ detailPage.total_payment_temporary}}</div>
          </el-form-item> -->
        <!-- </el-form> -->
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <lbButton type="goBack" icon="back" @click="isPieShow = false">返 回</lbButton>
        <lbButton type="orign" icon="confirm" @click="isPieShow = false">确 定</lbButton>
      </span> -->
    </lebo-dialog>
    <!-- <lebo-dialog append-to-body :title="dialogTitle" :isShow="isShowDialog" width="60%" @close="isShowDialog=false" footerSlot>
      <div id="bar_dialog_box"></div>

    </lebo-dialog> -->
  </div>
</template>

<script>
import { getTemporaryParkingPaymentList, getTemporaryParkingDetailsOfTheSumPaymentList, getTemporaryParkingHistogramPaymentList, getTemporaryParkingPaymentMethondList } from '@/api/financialStatement'
import { mapActions, mapState, mapGetters } from 'vuex'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      dialogTitle: '',
      // isShowDialog: false,
      // 查询表单
      queryForm: {
        time_type: 1,
        parking_id: '',
        start_time: new Date().setDate((new Date().getDate() - 1)),
        PageSize: 10,
        PageIndex: 1
      },
      // 禁用选择未来日期
      pickerOptions: {
        disabledDate (time) {
          // console.log(time);
          // console.log(time.getTime() < new Date('2021-06-06').getTime())
          // console.log(time.getTime() > (Date.now() - 86400000));
          return time.getTime() > (Date.now() - 86400000) || time.getTime() < new Date('2021-06-06').getTime()
        }
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 统计
      total_amount: 0.00,
      // 是否展示查看饼状统计图
      isPieShow: false,
      // 详情
      detailPage: {},
      loading: false, // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '时间',
            prop: 'report_time',
            slot: true
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '应收',
            prop: 'calculate_cost_temporary',
            slot: true
          }, {
            label: '实收',
            prop: 'total_payment_income_temporary',
            slot: true
          }, {
            label: '优惠',
            prop: 'total_discount_amount',
            slot: true
          }, {
            label: '退款',
            prop: 'total_payment_refund_temporary',
            slot: true
          }, {
            label: '总计',
            prop: 'total_payment_temporary',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetTemporaryParkingHistogramPaymentList()
    this.fnGetTemporaryParkingPaymentMethondList()
    this.fnGetParkingPaymentList()
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
  },
  methods: {
    // 查看停车场临停车缴费柱状图报表
    async fnGetTemporaryParkingHistogramPaymentList () {
      this.loading = true
      var time = this.processingTime(this.queryForm.time_type, this.queryForm.start_time)
      const res = await getTemporaryParkingHistogramPaymentList({
        time_type: this.queryForm.time_type,
        parking_id: this.queryForm.parking_id,
        start_time: time
      })
      console.log('--查看停车场临停车缴费柱状图报表--', res.Data)
      if (res && res.Code === 200) {
        var xAxisData = []
        var seriesData = []
        var xAxis
        if (this.queryForm.time_type === 1) { // 日报
          xAxisData = []
          seriesData = []
          if (res.Data.length > 0) {
            var data = res.Data.sort((a, b) => { return a.str - b.str })
            // console.log('--日报=排序--', data);
            data.forEach(item => {
              var str = item.str > 9 ? item.str : '0' + item.str
              xAxisData.push(str + ':00~' + str + ':59')
              seriesData.push(item.amount)
            })
            xAxis = {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                // interval: 0,
                // rotate: 40,
                fontSize: 12,
                formatter: function (params, index) {
                  return params
                }
              }
            }
            this.getBarChart(xAxis, seriesData)
          } else {
            xAxisData = [
              '00:00~00:59',
              '01:00~01:59',
              '02:00~02:59',
              '03:00~03:59',
              '04:00~04:59',
              '05:00~05:59',
              '06:00~06:59',
              '07:00~07:59',
              '08:00~08:59',
              '09:00~09:59',
              '10:00~10:59',
              '11:00~11:59',
              '12:00~12:59',
              '13:00~13:59',
              '14:00~14:59',
              '15:00~15:59',
              '16:00~16:59',
              '17:00~17:59',
              '18:00~18:59',
              '19:00~19:59',
              '20:00~20:59',
              '21:00~21:59',
              '22:00~22:59',
              '23:00~23:59'
            ]
            seriesData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            xAxis = {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                // interval: 0,
                // rotate: 60,
                fontSize: 11,
                formatter: function (params, index) {
                  return params
                }
              }
            }
            this.getBarChart(xAxis, seriesData)
          }
        } else if (this.queryForm.time_type === 2) { // 周报
          xAxisData = []
          seriesData = []
          res.Data.forEach(item => {
            xAxisData.push(`第${item.str}周${item.start_time}~${item.end_time}`)
            seriesData.push(item.amount)
          })
          xAxis = {
            type: 'category',
            data: xAxisData,
            axisLabel: {
              interval: 0,
              rotate: 0,
              fontSize: 11,
              formatter: function (params, index) {
                // console.log(params);
                // console.log(index);
                if (params.length > 3) {
                  return params.substring(0, 3) + '\n' + params.substring(3, params.length)
                }
              }
            }
          }
          this.getBarChart(xAxis, seriesData)
        } else if (this.queryForm.time_type === 3) { // 月报
          xAxisData = []
          seriesData = []
          res.Data.forEach(item => {
            xAxisData.push(`${item.str}日`)
            seriesData.push(item.amount)
          })
          xAxis = {
            type: 'category',
            data: xAxisData,
            axisLabel: {
              interval: 0,
              rotate: 0,
              fontSize: 10,
              formatter: function (params, index) {
                return params
              }
            }
          }
          this.getBarChart(xAxis, seriesData)
        } else if (this.queryForm.time_type === 4) { // 年报
          xAxisData = []
          seriesData = []
          res.Data.forEach(item => {
            xAxisData.push(`${item.str}月`)
            seriesData.push(item.amount)
          })
          xAxis = {
            type: 'category',
            data: xAxisData,
            axisLabel: {
              interval: 0,
              rotate: 0,
              fontSize: 12,
              formatter: function (params, index) {
                return params
              }
            }
          }
          this.getBarChart(xAxis, seriesData)
        }
      } else {
        this.loading = false
      }
    },
    // 柱状图
    getBarChart (xAxis, seriesData) {
      // 基于准备好的dom，初始化echarts实例
      var dom = document.getElementById('bar_box')
      if (!dom) return
      var myChart = echarts.init(dom)
      // 绘制图表
      var option = {
        //  标题
        title: {
          text: '单位：元',
          textStyle: {
            color: '#666666',
            fontSize: 14
          }
        },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          show: true,
          formatter: '{b}: {c}元'
        },
        xAxis: xAxis,
        yAxis: {
          type: 'value'
        },
        grid: {
          left: '1%',
          right: '1%',
          top: '15%',
          bottom: '8%',
          containLabel: true

        },
        series: [{
          data: seriesData,
          type: 'bar',
          barWidth: '15',
          itemStyle: {
            // 柱形图圆角，鼠标移上去效果
            emphasis: {
              barBorderRadius: [10, 10, 10, 10]
            },
            normal: {
              // 柱形图圆角，初始化效果
              barBorderRadius: [10, 10, 10, 10],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                [
                  { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                  { offset: 0.3, color: 'rgba(1, 166, 251, 0.75)' },
                  { offset: 0.8, color: 'rgba(1, 166, 251, 0.5)' },
                  { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                ],
                false
              )
            }
          }
        }]
      }
      myChart.clear()
      myChart.setOption(option)
      var _this = this
      myChart.off('click')
      // myChart.on('click', function (params) {
      //   console.log('myChart----', params.name)
      //   var starTime
      //   var endTime
      //   if (_this.queryForm.time_type === 1) {
      //     _this.dialogTitle = '日报详细缴费'
      //     starTime = _this.$moment(_this.queryForm.start_time).format('YYYY-MM-DD') + ' ' + params.name.substring(0, 2) + ':00:00'
      //     // console.log(starTime);
      //   } else if (_this.queryForm.time_type === 2) { // 周报
      //     _this.dialogTitle = '周报详细缴费'
      //     var arr = params.name.substring(3, params.name.length).split('~')
      //     starTime = arr[0] + ' 00:00:00'
      //     endTime = arr[1] + ' 00:00:00'
      //   } else if (_this.queryForm.time_type === 3) { // 月报
      //     _this.dialogTitle = '月报详细缴费'
      //     starTime = _this.$moment(_this.queryForm.start_time).format('YYYY-MM') + '-' + params.name.substring(0, params.name.length - 1) + ' 00:00:00'
      //     // console.log(starTime);
      //   } else if (_this.queryForm.time_type === 4) { // 年报
      //     _this.dialogTitle = '年报详细缴费'
      //     starTime = _this.$moment(_this.queryForm.start_time).format('YYYY') + '-' + params.name.substring(0, params.name.length - 1) + '-01 00:00:00'
      //     // console.log(starTime);
      //   }
      //   var obj = {
      //     time_type: _this.queryForm.time_type,
      //     parking_id: _this.queryForm.parking_id,
      //     start_time: starTime,
      //     end_time: endTime
      //   }
      //   _this.fngetDetail(obj)
      // })
      this.loading = false
    },
    // async fngetDetail (obj) {
    //   console.log('obj-----', JSON.stringify(obj))
    //   const res = await getTemporaryParkingDetailsOfTheSumPaymentList(obj)
    //   console.log(res)
    //   if (res && res.Code === 200) {
    //     this.isShowDialog = true
    //     var sourceData = []
    //     res.Data.forEach(item => {
    //       var a = []
    //       a.push(item.parking_name)
    //       a.push(item.parking_id)
    //       a.push(item.amount)
    //       sourceData.push(a)
    //     })
    //     var that = this
    //     this.$nextTick(() => {
    //       that.fnDetailBar(sourceData)
    //     })
    //   }
    // },
    // 详细柱状图
    fnDetailBar (sourceData) {
      var dom = document.getElementById('bar_dialog_box')
      if (!dom) return
      var myChart = echarts.init(dom)
      var option = {
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: function (data, i) {
            return `${data[0].axisValueLabel}：${data[0].data[2]} 元`
          }
        },
        dataset: [{
          dimensions: ['parking_name', 'parking_id', 'amount'],
          source: sourceData
        },
        {
          transform: {
            type: 'sort',
            config: { dimension: 'amount', order: 'desc' }
          }
        }],
        xAxis: {
          type: 'category',
          axisLabel: { interval: 0, rotate: 45 }
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '10%',
          bottom: '25%'
        },
        dataZoom: [ // 区域缩放
          {
            show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
            type: 'slider', // slider表示有滑动块的，inside表示内置的
            filterMode: 'empty',
            xAxisIndex: 0, // 设置 dataZoom-inside 组件控制的 x轴,可以用数组表示多个轴
            start: 0, // 数据窗口范围的起始百分比,表示30%
            end: 25, // 数据窗口范围的结束百分比,表示70%
            orient: 'horizontal', // 布局方式是横还是竖。不仅是布局方式，对于直角坐标系而言，也决定了，缺省情况控制横向数轴还是纵向数轴。'horizontal'：水平。'vertical'：竖直。
            zoomLock: true, // 是否锁定选择区域（或叫做数据窗口）的大小。如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
            left: 'center', // 组件离容器左侧的距离,'left', 'center', 'right','20%'
            top: 'top', // 组件离容器上侧的距离,'top', 'middle', 'bottom','20%'
            right: 'auto', // 组件离容器右侧的距离,'20%'
            bottom: 'auto' // 组件离容器下侧的距离,'20%'
          }
        ],
        yAxis: {},
        series: {
          type: 'bar',
          encode: { x: 'parking_name', y: 'amount' },
          datasetIndex: 1,
          barMaxWidth: 50,
          barWidth: '15',
          itemStyle: {
            // 柱形图圆角，鼠标移上去效果
            emphasis: {
              barBorderRadius: [10, 10, 10, 10]
            },
            normal: {
              // 柱形图圆角，初始化效果
              barBorderRadius: [10, 10, 10, 10],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                [
                  { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                  { offset: 0.3, color: 'rgba(1, 166, 251, 0.75)' },
                  { offset: 0.8, color: 'rgba(1, 166, 251, 0.5)' },
                  { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                ],
                false
              )
            }
          }
        }
      }
      myChart.clear()
      myChart.setOption(option)
    },
    // 报表类型发生改变
    typeChange () {
      this.queryForm.start_time = new Date().setDate((new Date().getDate() - 1))
      this.onSubmit()
    },
    // 查看停车场临停车缴费方式汇总报表
    async fnGetTemporaryParkingPaymentMethondList () {
      var time = this.processingTime(this.queryForm.time_type, this.queryForm.start_time)
      const res = await getTemporaryParkingPaymentMethondList({
        time_type: this.queryForm.time_type,
        parking_id: this.queryForm.parking_id,
        start_time: time
      })
      // console.log(res.Data);
      if (res && res.Code === 200) {
        var seriesData = []
        var total = 0
        seriesData.push(
          { value: res.Data.wechat_payment_temporary, name: '微信' },
          { value: res.Data.alipay_payment_temporary, name: '支付宝' },
          { value: res.Data.cash_payment_temporary, name: '现金' },
          { value: res.Data.ETC_payment_temporary, name: 'ETC' }
          // { value: res.Data.other_payment, name: "其它" }
        )
        total = res.Data.wechat_payment_temporary + res.Data.alipay_payment_temporary + res.Data.cash_payment_temporary + res.Data.ETC_payment_temporary
        this.getPieChart(seriesData, total)
      }
    },
    // 扇形图
    getPieChart (seriesData, total) {
      var dom = document.getElementById('pie_box')
      if (!dom) return
      //  基于准备好的dom，初始化echarts实例
      var myChart1 = echarts.init(dom)
      var colorList = ['#33c0d5', '#1acd90', '#e39f28', '#f55265']
      //  绘制图表
      var option1 = {
        color: colorList,
        title: [
          {
            // 第一个圆环标题
            text: '总缴费:', // 主标题
            textStyle: {
              // 副标题样式
              color: '#666666',
              fontSize: 14,
              fontWeight: '700'
            },
            left: '22%', // 定位到适合的位置
            bottom: '0%', // 定位到适合的位置
            textAlign: 'center' // 主、副标题水平居中显示
          }, {
            text: total.toFixed(2) + '元', // 主标题
            textStyle: {
              // 主标题样式
              color: '#01a6fb',
              fontSize: 14,
              fontWeight: '700'
            },
            left: '30%', // 定位到适合的位置
            bottom: '0%', // 定位到适合的位置
            textAlign: 'left' // 主、副标题水平居中显示
          }
        ],
        legend: {
          // 强制设置图形为圆。
          icon: 'circle',
          orient: 'vertical',
          right: '20%',
          top: '20%',
          textStyle: {
            color: '#666666',
            fontSize: 14,
            align: 'left',
            verticalAlign: 'middle'
          },
          data: seriesData
        },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'item',
          formatter: function (params) {
            var tip = ''
            if (params.seriesIndex === 0) {
              tip = `<div style="text-align: left;">${params.name}：<span style="font-weight: 700;color:${params.color};">缴费${params.value}元</span></div>
              <div style="text-align: left;">占比：<span style="font-weight: 700;color:${params.color};">${params.percent}%</span></div>`
            } else {
              tip = ''
            }
            return tip
          }
        },
        series: [
          // 环形展示层
          {
            type: 'pie',
            color: colorList,
            radius: ['40%', '55%'],
            center: ['30%', '40%'],
            label: {
              show: false
            },
            data: seriesData
          },
          // 内部设置
          {
            type: 'pie',
            radius: ['30%', '0%'],
            center: ['30%', '40%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 10,
                shadowColor: 'rgba(1, 1, 1, 0.1)',
                color: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          },
          // 边框
          {
            type: 'pie',
            radius: ['70%', '69%'],
            // 饼图位置参数
            center: ['30%', '40%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 20,
                shadowColor: 'rgba(1, 166, 251, 0.3)',
                borderColor: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          }
        ]
      }
      myChart1.clear()
      myChart1.setOption(option1)
    },
    // 查看停车场临停车缴费报表
    async fnGetParkingPaymentList () {
      var time = this.processingTime(this.queryForm.time_type, this.queryForm.start_time)
      // console.log(time);
      const res = await getTemporaryParkingPaymentList({
        time_type: this.queryForm.time_type,
        parking_id: this.queryForm.parking_id,
        start_time: time,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      // console.log(res.Data.DataList);
      if (res && res.Code === 200) {
        if (res.Data.DataList.length > 0) {
          this.total = res.Data.TotalCount
          this.tableData = res.Data.DataList
        } else {
          this.total = 0
          this.tableData = []
        }
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
        this.total_amount = res.Data.total_amount
      }
    },
    // 根据报表类型处理时间
    processingTime (type, time) {
      // console.log(type);
      // console.log(time);
      switch (type) {
        case 1:
          return this.$moment(time).format('YYYY-MM-DD')
        case 2:
          return this.$moment(time).format('YYYY-MM') + '-01'
        case 3:
          return this.$moment(time).format('YYYY-MM') + '-01'
        case 4:
          return this.$moment(time).format('YYYY') + '-01-01'
      }
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      // this.processingTime(this.queryForm.time_type, this.queryForm.start_time)
      this.fnGetTemporaryParkingHistogramPaymentList()
      this.fnGetTemporaryParkingPaymentMethondList()
      this.fnGetParkingPaymentList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetParkingPaymentList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetParkingPaymentList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetParkingPaymentList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetParkingPaymentList()
    // },
    // 导出
    async exportFile () {
      var time = this.processingTime(this.queryForm.time_type, this.queryForm.start_time)
      // console.log(time);
      const res = await getTemporaryParkingPaymentList({
        time_type: this.queryForm.time_type,
        parking_id: this.queryForm.parking_id,
        start_time: time,
        PageSize: 0,
        PageIndex: 0
      })
      // console.log(res.Data.DataList);
      var tableData = res.Data.DataList
      if (tableData.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date().setDate((new Date().getDate() - 1))).format('YYYY-MM-DD')
        var title = this.select(this.queryForm.time_type)
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '时间',
            '停车场',
            '应收',
            '实收',
            '微信实收',
            '支付宝实收',
            '现金实收',
            'ETC实收',
            '优惠',
            '退款',
            '微信退款',
            '支付宝退款',
            '现金退款',
            'ETC退款',
            '总计'
          ]
          const filterVal = [
            'report_time',
            'parking_name',
            'calculate_cost_temporary',
            'total_payment_income_temporary',
            'wechat_payment_income_temporary',
            'alipay_payment_income_temporary',
            'cash_payment_income_temporary',
            'ETC_payment_income_temporary',
            'total_discount_amount',
            'total_payment_refund_temporary',
            'wechat_payment_refund_temporary',
            'alipay_payment_refund_temporary',
            'cash_payment_refund_temporary',
            'ETC_payment_refund_temporary',
            'total_payment_temporary'
          ]
          const list = tableData
          const data = this.formatJson(filterVal, list)
          // console.log(data);
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '临停车缴费' + title + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    select (type) {
      switch (type) {
        case 1:
          return '日报'
        case 2:
          return '周报'
        case 3:
          return '月报'
        case 4:
          return '年报'
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        var map = {}
        this.$set(map, j, v[j])
        return this.formatter(map, { property: j })
        // 为formatter专门为了码表渲染的组件
      })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // 日期处理
      if (column.property === 'report_time') {
        if (this.queryForm.time_type === 1) {
          return this.$moment(row.report_time).format('YYYY-MM-DD')
        } else if (this.queryForm.time_type === 2) {
          return row.report_time
        } else if (this.queryForm.time_type === 3) {
          return this.$moment(row.report_time).format('YYYY年MM月')
        } else if (this.queryForm.time_type === 4) {
          return this.$moment(row.report_time).format('YYYY年')
        }
      } else if (column.property === 'parking_name') {
        return row[column.property]
      } else {
        return '￥' + row[column.property].toFixed(2)
      }
    },
    showPie (row) {
      this.isPieShow = true
      this.detailPage = row
      this.open()
      console.log(row)
    },
    // 饼状图
    initEcharts () {
      var dom = document.getElementById('show_box')
      if (!dom) return
      var total = 0
      var data = [
        { value: this.detailPage.wechat_payment_income_temporary, name: '微信', itemStyle: { color: '#5470c6' } },
        { value: this.detailPage.alipay_payment_income_temporary, name: '支付宝', itemStyle: { color: '#91cc75' } },
        { value: this.detailPage.cash_payment_income_temporary, name: '现金', itemStyle: { color: '#fac858' } },
        { value: this.detailPage.ETC_payment_income_temporary, name: 'ETC', itemStyle: { color: '#ee6666' } }
      ]
      total = this.detailPage.wechat_payment_income_temporary + this.detailPage.alipay_payment_income_temporary + this.detailPage.cash_payment_income_temporary + this.detailPage.ETC_payment_income_temporary
      // 基于准备好的dom，初始化echarts实例
      var myChart2 = echarts.init(dom)
      // 绘制图表
      var colorList = ['#33c0d5', '#1acd90', '#e39f28', '#f55265']
      //  绘制图表
      var option2 = {
        color: colorList,
        title: [
          {
            // 第一个圆环标题
            text: '总缴费:', // 主标题
            textStyle: {
              // 副标题样式
              color: '#666666',
              fontSize: 16,
              fontWeight: '700'
            },
            left: '22%', // 定位到适合的位置
            bottom: '0%', // 定位到适合的位置
            textAlign: 'center' // 主、副标题水平居中显示
          }, {
            text: total.toFixed(2) + '元', // 主标题
            textStyle: {
              // 主标题样式
              color: '#01a6fb',
              fontSize: 16,
              fontWeight: '700'
            },
            left: '30%', // 定位到适合的位置
            bottom: '0%', // 定位到适合的位置
            textAlign: 'left' // 主、副标题水平居中显示
          }
        ],
        legend: {
          // 强制设置图形为圆。
          icon: 'circle',
          orient: 'vertical',
          right: '15%',
          bottom: 'center',
          textStyle: {
            color: '#666666',
            fontSize: 14,
            align: 'left',
            verticalAlign: 'middle'
          },
          data: data
        },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'item',
          formatter: function (params) {
            var tip = ''
            if (params.seriesIndex === 0) {
              tip = `<div style="text-align: left;">${params.name}：<span style="font-weight: 700;color:${params.color};">缴费${params.value}元</span></div>
              <div style="text-align: left;">占比：<span style="font-weight: 700;color:${params.color};">${params.percent}%</span></div>`
            } else {
              tip = ''
            }
            return tip
          }
        },
        series: [
          // 环形展示层
          {
            type: 'pie',
            color: colorList,
            radius: ['40%', '55%'],
            center: ['35%', '45%'],
            label: {
              show: false
            },
            data: data
          },
          // 内部设置
          {
            type: 'pie',
            radius: ['30%', '0%'],
            center: ['35%', '45%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 10,
                shadowColor: 'rgba(1, 1, 1, 0.1)',
                color: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          },
          // 边框
          {
            type: 'pie',
            radius: ['70%', '69%'],
            // 饼图位置参数
            center: ['35%', '45%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 20,
                shadowColor: 'rgba(1, 166, 251, 0.3)',
                borderColor: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          }
        ]
      }
      myChart2.clear()
      myChart2.setOption(option2)
    },
    open () {
      var that = this
      this.$nextTick(() => {
        //  执行echarts方法
        that.initEcharts()
      })
    }
  }
}
</script>

<style scoped lang="less">
.card_header {
  height: 40px;
}

.box-card {
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.top_content {
  display: flex;
  justify-content: space-between;

  .right_box {
    width: 20%;
    text-align: right;

    span {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}

.pay_content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  .echart_left {
    width: 30%;
    height: 100%;

    #pie_box,
    #bar_box {
      width: 100%;
      height: 220px;
    }
  }
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 200px;
  }
}

.dialog_content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  #show_box {
    width: 45%;
    height: 350px;
  }

  .show_form {
    flex: 1;
    height: 300px;
   .tableClass{
    border-color:rgba(233,233,233,.2);
    box-shadow: 0px 0px 10px 5px rgba(233,233,233,.8);
    border-radius: 1px 1px 1px 1px;
   }
  }
}

#bar_dialog_box {
  min-width: 100%;
  min-height: 500px;
}

table {
  width: 100%;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  tr {
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;

    .first_td {
      width: 15%;
    }

    td {
      text-align: center;
    }
  }
}
// 列表最大高度
/deep/.el-table .el-table__body-wrapper{
  max-height: 230px !important;
}</style>
